.Image_wrapper {
    position: relative;
}
.Image_overlay_preview {
    z-index: 10;
    background-color: transparent;
    position: relative;
    top: 0;
    left: 0;
}
.Image_overlay {
    z-index: 10;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
}
.Canvas_overlay{
    z-index: 20;
    position: relative;
}

.Scene_overlay {
    z-index: 5;
    position: absolute;
    top: -10px;
    left: -10px;
}

.Scene_overlay_faded{
    z-index: 5;
    position: absolute;
    top: -10px;
    left: -10px;
    opacity: 40%;
}

.Scene_background {
    z-index: 1;
    position: absolute;
    top: -10px;
    left: -10px;
}

.Image_hidden{
    display: none;
}

.ScenePreview_overlay {
    z-index: 5;
    position: absolute;
}

.ScenePreview_overlay_faded{
    z-index: 5;
    position: absolute;
    opacity: 40%;
}

.ScenePreview_background {
    z-index: 1;
    position: relative;
}

.ScenePreview_dragon{
    z-index: 10;
    position: absolute;
    left: 329px;
    top: 105px;
}

.Vista_wrapper {
    position: relative;
    min-height: 220px;
    min-width: 144px;
    margin-top: 20px;
    left: -72px;
}

.VistaPreview_icon{
    z-index: 10;
    position: absolute;
    margin-top: 20px;
    margin-left: 42px;
    width: 60px;
    height: 60px;
    border: 1px solid black;
}

.VistaPreview_iconbg{
    z-index: 9;
    position: absolute;
    margin-top: 20px;
    margin-left: 42px;
    width: 60px;
    height: 60px;
    border: 1px solid black;
}

.VistaPreview_vista{
    z-index: 2;
    position: absolute;
}

.VistaPreview_vistabg{
    z-index: 1;
    position: absolute;
}
